<template>
  <div class="home_top">
    <div class="inner top-tip">
      <img src="@/assets/images/loanImg/top-icon.png" alt="">
      <p>UltraNaira uses multiple encryption algorithms to protect your data security and privacy. And we will not access your contacts list and photo gallery.</p>
    </div>
    <div class="inner inner1">
      <div class="left_item">
        <h1>Online personal loan App</h1>
        <h1>Easy and flexible cash loan</h1>
        <!-- <div class="item_progress">
          <p>75.0000$</p>
          <img src="@/assets/images/loanImg/icon_progress.png" alt="">
        </div> -->
        <!-- <div class="item_info">
          <img class="info_img1" src="@/assets/images/loanImg/icon_info.png" alt="">
          <div class="info_tips">
            <p><img class="info_img1" src="@/assets/images/loanImg/icon_item_tick.png" alt="">5 minutos para procesar su solicitud</p>
            <p><img class="info_img1" src="@/assets/images/loanImg/icon_item_tick.png" alt="">Sin garantía</p>
            <p><img class="info_img1" src="@/assets/images/loanImg/icon_item_tick.png" alt="">Préstamo de 24 horas</p>
          </div>
          <img class="info_img2" src="@/assets/images/loanImg/icon_info2.png" alt="">
        </div> -->
        <!-- <h5>Descargue la APP para comprobar rápidamente su cuota, Click</h5> -->
        <div class="item_download" @click="downloadApp()">
          <!-- <img src="@/assets/images/loanImg/icon_download.png" alt=""> -->
          <!-- <p>Descargar APP</p> -->
          <!-- <a href="">
            <img src="@/assets/images/loanImg/download_now.png" alt="">
          </a> -->
          <img src="@/assets/images/loanImg/Google-play.png" alt="">
        </div>
      </div>
      <div class="right_item">
        <img src="@/assets/images/loanImg/top_app.png" alt="">
      </div>
    </div>
    <div id="abouts" class="inner inner1-1">
      <h1>About US</h1>
      <p>
        UltraNaira gives the privilege of applying for a personal loan at your convenience . Everyone who meets the basic
        requirements can apply with us. UltraNaira Brings you close to a team of professionals to help you through a hassle free and
        quicker procedure of getting your loan approved. You just need to fill out some basic information online, then you can enjoy
        the benefits that come with the package. Choose the loan according to your need and get the money safely transferred to
        your account instantly after approval. Let us help you through!
      </p>
    </div>
    <div class="inner inner1-2">
      <img src="@/assets/images/loanImg/inner1-2.png">
    </div>
    <div id="loan" class="inner inner2">
      <h1 class="title">Loan process</h1>
      <div class="items">
        <div class="left_item">
          <img src="@/assets/images/loanImg/icon_people.png" alt="">
          <div class="item_download_2" @click="downloadApp()">
            <img src="@/assets/images/loanImg/icon_download.png" alt="">
            <p>Install Now</p>
          </div>
        </div>
        <div class="right_item">
          <!-- <h1>What can UltraNaira provide to you?</h1> -->
          <div class="item_info">
            <div class="info_tips">
              <p><img class="info_img1" src="@/assets/images/loanImg/app-icon-1.png" alt="">Install UltraNaira app from Google Play and register an account with your mobile number.</p>
              <p><img class="info_img1" src="@/assets/images/loanImg/app-icon-2.png" alt="">Fill out the basic application forms online within 3-5 mins.</p>
              <p><img class="info_img1" src="@/assets/images/loanImg/app-icon-3.png" alt="">Submit your application and get approved soon.</p>
              <p><img class="info_img1" src="@/assets/images/loanImg/app-icon-4.png" alt="">Loan disbursed instantly into your account.</p>
              <p><img class="info_img1" src="@/assets/images/loanImg/app-icon-5.png" alt="">Repay your loan on time.</p>
              <p><img class="info_img1" src="@/assets/images/loanImg/app-icon-6.png" alt="">Get another loan with a higher amount, longer term and lower fees.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="privacy" class="inner inner3">
      <h3>Privacy Policy</h3>
      <iframe
        ref="myIframe"
        class="child"
        :src="url"
        frameborder="0"
        :style="{width: width, height:height}"
      />
    </div>
    <!-- <div class="inner inner4">
      <div class="left_item">
        <img src="@/assets/images/loanImg/icon_people2.png" alt="">
      </div>
      <div class="right_item">
        <p>¿Cómo reembolsar?</p>
        <img src="@/assets/images/loanImg/icon_inner4.png" alt="">
        <div class="item_tips">
          Proporcionaremos el modo de pago conveniente que usted pueda
          pagar su préstamo rápidamente y fácilmente para guardar su crédito.
          Siempre puede ponerse en contacto con nuestros consultores
          profesionales después de pedir prestado y te ayudaremos
          eficazmente.”
        </div>
      </div>
    </div> -->
    <!-- <div class="inner inner5">
      <div class="inner5-bg">
        <h3>Testimonios de nuestros clientes</h3>
      </div>
      <div class="inner5_item">
        <div class="content">
          <div class="left_item">
            <div class="item_info">
              <p>170 TORRE VENEZIA SUITES TIMOG AVE. COR. SCT. SANTIAGO ST. LAGING HANDA</p>
            </div>
            <img src="@/assets/images/loanImg/home-inner5-1.png" alt="">
          </div>
        </div>
        <div class="content">
          <div class="left_item">
            <div class="item_info">
              <p>pesogoapp@gmail.com</p>
            </div>
            <img src="@/assets/images/loanImg/home-inner5-2.png" alt="">
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: 'https://h5.ultranaira.com/#/privacy_policy',
      height: (window.innerHeight / 2) + 'px', //  - 43
      width: '100%'
    }
  },
  methods: {
    downloadApp() {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.ultranaira.credit.loan'
    }
  }
}
</script>

<style lang="scss" scoped>

  .home_top{
    min-width: 1200px;
    background: #fff;
    .inner{
      width: 100%;
      padding:50px calc((100% - 1100px) / 2) 0;
      margin: 0 auto;
    }
    .top-tip{
      background: #E9EEED;
      color: #36665A;
      display: flex;
      width: calc(100% - ((100% - 1100px)));
      margin: 30px auto 0;
      padding: 0 0 0 22px;
      border-radius: 10px;
      // justify-content: center;
      align-items: center;
      p{
        font-size: 14px;
        line-height: 45px;
      }
      img{
        width: 25px;
        height: 25px;
        vertical-align:middle;
        margin-right: 20px;
      }
      line-height: 30px;
    }
    .inner1{
      height: 513px;
      .left_item{
        background: #fff;
        float: left;
        padding: 25px;
        border-radius: 10px;
        width: 450px;
        h1{
          font-size: 45px;
          color: #333333;
          font-weight: 700;
          margin-bottom: 20px;
          line-height: 55px;
        }
        h4{
          font-size: 16px;
          line-height: 22px;
          color: #333333;
          font-weight: normal;
          margin-bottom: 20px;
        }
        .item_progress{
          width: 230px;
          p{
            width: 90px;
            text-align: center;
            line-height: 30px;
            border-radius: 5px;
            color: white;
            font-size: 12px;
            font-weight: bold;
            margin: 0 auto 10px;
            background: #4488EB;
          }
          img{
            width: 245px;
            height: 15px;
          }
        }
        .item_info{
          margin: 20px 0;
          display: flex;
          height: 170px;
          .info_img1{
            width: 30px;
          }
          .info_tips{
            display: flex;
            flex-direction: column;
            p{
              flex: 1;
              display: flex;
              box-sizing: border-box;
              // margin-left: 10px;
              font-size: 12px;
              color: #666666;
              align-items: center;
              img{
                margin-right: 10px;
              }
            }
            // }
            // p:nth-child(1){
            //   padding-top: 8px;
            // }
            // :nth-child(2){
            //   align-items: center;
            // }
            // :nth-child(3){
            //   align-items:flex-end;
            //   padding-bottom: 8px;
            // }
          }
          .info_img2{
            width: 135px;
            height: 135px;
          }
        }
      }
      .right_item{
        float: right;
        // margin-right: 50px;
        img{
          width: 550px;
        }
      }
    }
    .inner1-1{
      h1{
        margin: 0 auto 30px;
        text-align: center;
      }
      padding-bottom: 60px;
      padding-top: 90px;
      background: url('~@/assets/images/loanImg/inner_bg.png') no-repeat #36665A;
      background-size: 30% 100%;
      color: white;
      text-align: left;
      p{
        font-size: 14px;
        font-weight: normal !important;
        line-height: 26px;
        padding: 0 120px;
      }
    }
    .inner1-2{
      padding:20px 40px;
      width: 100%;
      background: #E9EEED;
      img{
        display: block;
        width: 90%;
        margin: 0 auto;
      }
    }
    .inner2{
      background:#FFFFFF;
      padding-bottom: 80px;
      .title{
        text-align: center;
        margin-bottom: 50px;
      }
      .items{
        display: flex;
        justify-content: space-between;
      }
      .left_item{
        flex: 1;
        img{
          display: block;
          margin: 0 auto;
          width: 205px;
          height: 400px;
        }
        .item_download_2{
          margin: 30px auto;
          width: 140px;
          background: #333333;
          display: flex;
          color: white;
          justify-content: center;
          align-items: center;
          line-height: 30px;
          border-radius: 5px;
          font-size: 12px;
          img{
            margin: 0;
            width: 10px;
            height: 11px;
            margin-right: 10px;
            vertical-align:middle;
          }
        }
      }
      .right_item{
        margin-right:30px;
        p{
          // margin-top: 30px;
          color:#2665D9 ;
          font-size: 20px;
          font-weight: bold;
        }
        .item_tips{
          display: flex;
          width: 460px;
          justify-content: space-between;
          p{
            width: 70px;
            font-size: 12px;
            color: #333333;
            text-align: center;
          }
        }
        .item_info{
          margin: 0px 0 20px;
          display: flex;
          .info_img1{
            width: 30px;
          }
          .info_tips{
            display: flex;
            flex-direction: column;
            // height: 350px;
            p{
              padding: 15px 35px;
              border-radius: 10px;
              background: white !important;
              box-shadow: 0px 5px 20px 1px rgba(78,78,78,0.16);
              display: flex;
              font-size: 12px;
              color: #666666;
              align-items: center;
              margin-bottom: 20px;
              img{
                width: 30px !important;
                height: 30px !important;
                margin-right: 20px;
              }
            }
            & p:nth-last-child(1){
              margin-bottom: 0px !important;
            }
          }
        }
      }
    }
    .inner3{
      background: #FAFAFA;
      margin: 0 auto 50px;
      h3{
        font-size: 24px;
        text-align: center;
        line-height: 50px;
      }
      img{
        width: 100%;
      }
      .inner3_item{
        margin: 50px 0;
        display: flex;
        justify-content: space-between;
        .content{
          background: white;
          width: 280px;
          padding: 25px 20px 30px 20px;
          border-radius: 10px;
          box-shadow: 0px 3px 20px 1px rgba(93,93,93,0.1600);
          .top_item{
            display: flex;
            justify-content: space-between;
            p{
              width: 88px;
            }
            img{
              width: 40px;
              height: 30px;
            }
          }
          .line{
            width: 120px;
            height: 2px;
            background: #4485EC;
            margin: 15px 0;
          }
          .bottom_item{
            line-height: 18px;
            color: #666666;
          }
        }
      }
    }
    .inner4{
      position: relative;
      height: 364px;
      background:#F2FAFF ;
      display: flex;
      justify-content: space-between;
      .left_item{
        img{
          width: 280px;
          height: 300px;
        }
      }
      .right_item{
        margin-right:50px;
        p{
          margin: 40px 0;
          color:#2665D9 ;
          font-size: 20px;
          font-weight: bold;
        }
        img{
          position: absolute;
          width: 190px;
          height:220px;
          right: calc(15%);
          top: 0px;
        }
        .item_tips{
          background:#E0EEFE ;
          padding: 20px 10px;
          border-radius: 10px;
          width: 450px;
          line-height: 20px;
        }
      }
    }
    .inner5{
      padding: 0 0 50px 0;
      .inner5-bg{
        width: 100%;
        height: 250px;
        background: url("~@/assets/images/loanImg/inner5.png") no-repeat center;
        background-size: 100% 100%;
        padding: 50px 250px;
      }
      h3{
        font-size: 24px;
        color: white;
        float: right;
      }
      .inner5_item{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin: -100px 0 0;
        padding: 0 250px;
        font-size: 14px;
        text-align: center;
        line-height: 16px;
        .content{
          width: 300px;
          background: #fff;
          padding: 40px;
          border-radius: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 50px;
          box-shadow: 0 0 5px #999;
          img{
            margin-top: 50px;
            width: 120px;
          }
        }
      }
    }
    .item_download{
      display: flex;
      margin: 40px 0 0;
      width: 159px;
      // background: black;
      color: white;
      border-radius: 5px;
      align-items: center;
      justify-content: center;
      img{
        width: 159px !important;
        height: 50px !important;
        margin-right: 5px;
      }
    }
  }
@media only screen and (max-width: 720px){
  .home_top{
    min-width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      display: flex;
      flex-wrap: wrap;
    }
    .top-tip{
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
      p{
        padding: 0 30px;
        line-height: 30px;
      }
    }
    .inner1{
      height: 700px;
      position: relative;
      padding: 20px calc((100% - 1100px) / 2) 0;
      .left_item{
        margin: 0px 20px 20px;
        height: 500px;
        padding: 15px;
        h1{
          font-size: 35px;
          line-height: 50px;
        }
      }
      .right_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 350px !important;
          position: absolute;
          bottom: 0;
        }
      }
    }
    .inner1-1{
      padding: 50px 30px;
      p{
        padding: 0;
      }
      background-size:auto 100%;
    }
    .inner1-2{
      padding: 20px ;
      img{
        width: 100%;
      }
    }
    .inner2{
      // padding: 20px;
      // flex-direction: column;
      padding-bottom: 20px;
      .title{
        width: 100%;
      }
      .items{
        flex-direction: column;
      }
      .left_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img{
          width: 200px;
          height: 350px;
          // margin-bottom: 0px;
        }
      }
      .right_item{
        margin: 0 auto;
        width: calc(100vw - 40px);
        p{
          font-size: 18px;
        }
        // img{
        //   // width: 100%;
        //   // height: 60px;
        // }
        .item_tips{
          width: calc(100vw - 40px) !important;
          p{
            font-size: 12px !important;
            width: 70px;
          }
        }
      }
    }
    .inner3{
      width: calc(100vw - 40px) !important;
      margin: 0 auto  30px !important;
      padding: 15px 20px 0;
      h3{
        width: 100vw;
        font-size: 25px;
        // padding: 0 20px;
      }
      img{
        width: 90%;
        margin: 0 auto;
      }
      .inner3_item{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .content{
          margin: 20px 0;
        }
      }
    }
    .inner4{
      height: 600px;
      .left_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 200px;
          height: 220px;
        }
      }
      .right_item{
        width: calc(100vw - 40px) !important;
        margin: 0 auto !important;
        position: relative;
        .item_tips{
          width: 100%;
        }
        img{
          position: absolute;
          width: 100px;
          height: 140px;
          right: 0;
          top: -20px;
        }
      }
    }
    .inner5{
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw - 40px) !important;
      margin: 0 auto !important;
      h3{
        font-size: 18px;
      }
      .inner5-bg{
        padding: 20px  !important;
        height: 100px !important;
        font-size: 12px !important;
      }
      .inner5_item{
        margin:  0 auto;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .content{
          margin: 20px 0;
        }
      }
    }
  }
}
</style>
